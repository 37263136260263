// noinspection ES6CheckImport
import { createSelector } from 'reselect';
import * as constants from './activity.constants';
import * as schemaConstants from './activitySchema/activitySchema.constants';
import * as updateMembershipConstants from './views/conditions/updateMembershipCondition/updateMembership.constants';
import * as schemaSelectors from './activitySchema/activitySchema.selectors';
import * as appSelectors from '../App/selectors';
import * as imagesSelectors from '../Images/images.selectors';
import * as utils from './activity.selectors.utils';
import * as reducerConstants from '../../constants/reducer.constants';
import * as commonConstants from '../../common/components/common.components.constants';
import * as appConstants from '../App/app.constants';

export const getActivityModel = (state) => state.get(reducerConstants.ACTIVITY_BRANCH);
export const getActivityDataModel = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA]);
export const getActivitySimpleMode = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.GIFT_SIMPLE_MODE]);
export const getOriginalActivityDataModel = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.ORIGINAL_DATA]);
export const getActivityType = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.TYPE]);
export const getActivityName = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.NAME]);
export const getActivityDescription = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.DESCRIPTION]);
export const getActivityOriginalViewStatus = createSelector([getOriginalActivityDataModel], utils.calculateActivityViewStatus);
export const getSelectedTrigger = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.TRIGGER]);
export const getActivityDataLoadedFlag = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.ACTIVITY_DATA_LOADED]);
export const getUpdateMembershipTempData = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, updateMembershipConstants.UPDATE_MEMBERSHIP_TEMP_DATA]);
export const getCheckingActivityNameAvailabilityFlag = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.CHECKING_ACTIVITY_NAME_AVAILABILITY]);
export const getPublishOrSaveInProgressFlag = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.PUBLISH_OR_SAVE_IN_PROGRESS]);
export const getSaveAsDraftInProgressFlag = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.SAVE_AS_DRAFT_IN_PROGRESS]);
export const getFiltersInProgressFlag = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.FILTERS_IN_PROGRESS]);
export const getIsMembershipConditionsModalOpenFlag = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.MEMBERSHIP_GLOBAL_CONDITIONS_MODAL_IS_OPEN]);
export const getTemplate = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMPLATE]);
export const getTempDateTimeGlobalConditions = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_DATETIME_GLOBAL_CONDITIONS]);
export const getTempSchedulingData = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_SCHEDULING_DATA]);
export const getActivityCases = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.CASES]);
export const getTempApplyOnBundleMode = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_APPLY_ON_BUNDLE_MODEL]);
export const getMembershipGlobalConditionTempModel = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL]);
export const getMembershipStatusConditionTempModel = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_MEMBERSHIP_STATUS_CONDITION]);
export const getOneTimeActionFilteredPopulation = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.ONE_TIME_ACTION_FILTERED_POPULATION]);
export const getExportInProgress = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.EXPORT_IN_PROGRESS]);
export const getActivityCampaignKey = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.CAMPAIGN_KEY]);
export const getDateTimeGlobalCondition = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.DATETIME_GLOBAL_CONDITIONS]);
export const getLimitOccurrencesPerMemberGlobalConditionsTempModel = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS_TEMP_MODEL]);
export const getLimitOccurrencesPerMemberGlobalConditionsModel = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS]);
export const getOneTimeActionScheduling = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.ONE_TIME_ACTION_SCHEDULING]);
export const getMembershipGlobalConditionType = (state, isMemberFilter) => utils.getMembershipGlobalConditionTypeImpl(state, isMemberFilter);
export const getMembershipGlobalConditions = (state, isMemberFilter) => utils.getMembershipGlobalConditionsImpl(state, isMemberFilter);
export const getFilteredPopulationSize = (state, isMemberFilter) => isMemberFilter ? state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.ONE_TIME_ACTION_FILTERED_POPULATION, constants.FILTERED_NUM_OF_MEMBERS]) : null;
export const isActivityDisabled = createSelector([getActivityType, getActivityOriginalViewStatus], utils.isActivityDisabledImpl);
export const isOneTimeSaveSearchCriteriaAllowed = createSelector([getActivityDataModel], utils.isOneTimeSaveSearchCriteriaAllowedImpl);
export const getDraftOldFilteredPopulationFlag = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DRAFT_OLD_FILTERED_POPULATION_FLAG]);
export const isActivityDirty = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.IS_ACTIVITY_DIRTY]);
export const getGiftInstanceExpirationGlobalConditionModel = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION]);
export const getGiftInstanceExpirationGlobalConditionTempModel = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION_TEMP_MODEL]);
export const getPendingTrigger = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.PENDING_TRIGGER]);
export const getBackToActivityInStateFlag = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.BACK_TO_ACTIVITY_IN_STATE]);

// validation errors:
export const getAllValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.VALIDATION_ERRORS]);
export const getActivityValidationErrors = createSelector([getAllValidationErrors], utils.filterNonActivityValidationErrors);
export const getPunchCardCommunicationRulesErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.VALIDATION_ERRORS, constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_COMMUNICATION_RULES]);

export const getDatetimeGlobalConditionsValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.VALIDATION_ERRORS, constants.GLOBAL_CONDITIONS, constants.DATETIME_GLOBAL_CONDITIONS]);
export const getLimitOccurrencesGlobalConditionsValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.VALIDATION_ERRORS, constants.GLOBAL_CONDITIONS, constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS]);
export const getOneTimeActionValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.VALIDATION_ERRORS, constants.ONE_TIME_ACTION_SCHEDULING]);
export const getMembershipGlobalConditionValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.VALIDATION_ERRORS, constants.GLOBAL_CONDITIONS, constants.MEMBERSHIP_GLOBAL_CONDITIONS]);
export const getTempValidationErrors = (state, isMemberFilter = false) => isMemberFilter ? state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_FILTERS_ERRORS]) : state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_VALIDATION_ERRORS]);
export const getUpdateMembershipTempValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_VALIDATION_ERRORS, updateMembershipConstants.UPDATE_MEMBERSHIP_TEMP_DATA]);
export const getDatetimeGlobalConditionsTempValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_VALIDATION_ERRORS, constants.GLOBAL_CONDITIONS, constants.DATETIME_GLOBAL_CONDITIONS]);
export const getLimitOccurrencesGlobalConditionsTempValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_VALIDATION_ERRORS, constants.GLOBAL_CONDITIONS, constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS]);
export const getOneTimeActionTempValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_VALIDATION_ERRORS, constants.TEMP_SCHEDULING_DATA]);
export const getTempApplyOnBundleModeValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_VALIDATION_ERRORS, constants.TEMP_APPLY_ON_BUNDLE_MODEL]);
export const getMembershipGlobalConditionModalValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_VALIDATION_ERRORS, constants.GLOBAL_CONDITIONS, constants.MEMBERSHIP_GLOBAL_CONDITIONS]);
export const getTempModelMembershipGlobalConditionsValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.MEMBERSHIP_GLOBAL_CONDITIONS_TEMP_MODEL, constants.TEMP_VALIDATION_ERRORS]);
export const getFiltersConditionsValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_FILTERS_ERRORS, constants.GLOBAL_CONDITIONS, constants.MEMBERSHIP_GLOBAL_CONDITIONS]);
export const getFiltersNumberOfMembersValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_FILTERS_ERRORS, constants.NO_MEMBERS_FILTERED_ERROR]);
export const getGiftInstanceExpirationGlobalConditionValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.VALIDATION_ERRORS, constants.GLOBAL_CONDITIONS, constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION]);
export const getGiftInstanceExpirationGlobalConditionTempValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_VALIDATION_ERRORS, constants.GLOBAL_CONDITIONS, constants.INSTANCE_EXPIRATION_GLOBAL_CONDITION]);
export const getDisplayTabValidationErrors = createSelector([getAllValidationErrors], utils.getDisplayTabValidationErrorsImpl);
export const getScheduleParametersScheduleValueValidationError = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.VALIDATION_ERRORS, constants.BIRTHDAY_OR_ANNIVERSARY_PARAMETERS, constants.TRIGGER_SCHEDULE_VALUE]);

export const getActivityStats = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.ACTIVITY_STATS]);
export const getActivityABTestStats = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.ACTIVITY_AB_TEST_STATS]);
export const getActivityStatsLoadedStatus = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.ACTIVITY_STATS_LOADED]);
export const getActiveTab = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.ACTIVE_TAB]);
export const getBirthdayOrAnniversaryEventScope = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.BIRTHDAY_OR_ANNIVERSARY_PARAMETERS, constants.EVENT_SCOPE]);
export const getClubMemberAnniversaryEventScope = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.CLUB_MEMBER_ANNIVERSARY_PARAMETERS, constants.EVENT_SCOPE]);
export const getClubMemberAnniversaryScheduleObject = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.CLUB_MEMBER_ANNIVERSARY_PARAMETERS, constants.SCHEDULE]);
export const getTriggerScheduleValue = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.BIRTHDAY_OR_ANNIVERSARY_PARAMETERS, constants.TRIGGER_SCHEDULE_VALUE]);
export const getSchemaWithFeatures = createSelector([schemaSelectors.getSchema, appSelectors.getFeatures, getSelectedTrigger, appSelectors.getBusinessWallet], schemaSelectors.getSchemaWithFeaturesImpl);
export const getActionsForSelectedTrigger = createSelector([getSchemaWithFeatures, getSelectedTrigger, appSelectors.getFeatures, appSelectors.getBusinessBundlePlan, appSelectors.getIsComoUser], schemaSelectors.getActionsForSelectedTriggerImpl);
export const getActionsForMicroCampaign = createSelector([getSchemaWithFeatures], schemaSelectors.getActionsForMicroCampaignImpl);
export const getConditionsForSelectedTrigger = createSelector([getSchemaWithFeatures, getSelectedTrigger, schemaSelectors.getTransformedUpdateMembershipConditions], utils.getConditionsForSelectedTriggerImpl);
export const getPunchCommunicationTriggers = createSelector([schemaSelectors.getSchema], schemaSelectors.getPunchCommunicationTriggersImpl);
export const getPunchCommunicationActions = createSelector([schemaSelectors.getSchema], schemaSelectors.getPunchCommunicationActionsImpl);
export const getActivityTabsErrors = createSelector([getActivityValidationErrors, getDisplayTabValidationErrors], utils.getActivityTabsErrorsImpl);
export const getActivityTabs = createSelector([getActivityType], utils.getActivityTabsImpl);
export const getIsActivityDraft = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.STATUS]) === constants.ACTIVITY_STATUS_DRAFT ||
  state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.STATUS]) === '';
export const getActivityStatus = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.STATUS]);
export const getActivityExportMembersIsReady = (state) => utils.getActivityExportMembersIsReadyImpl(state);


export const getPromoCodeBulksSingleCodeValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.VALIDATION_ERRORS, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE_SINGLE_CODE]);
export const getPromoCodeBulksFileUploadedValidationErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.VALIDATION_ERRORS, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE_FILE_UPLOADED]);
export const getPromoCodeBulksSingle = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE_SINGLE_CODE]);
export const getPromoCodeBulks = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.PROMO_CODE_BULKS]);
export const getPromoCodeType = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.PROMO_CODE_BULKS_TYPE]);
export const getFreePromoCodesNumber = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.FREE_PROMO_CODES_NUMBER]);

export const getPromoCodeTakenFlag = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.VALIDATION_PROMO_CODE_TAKEN_FLAG]);

export const getPromoCodeBulksFileUploaded = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE_FILE_UPLOADED]);
export const getPromoCodesFileUploadProgress = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.PROMO_CODE_FILE_UPLOAD_IN_PROGRESS]);
export const getPromoCodeBulkMode = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE]);

export const getABTestMode = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.AB_TEST_MODE]);

export const getDisabledGlobalConditionsForSelectedTrigger = createSelector(
  [getSchemaWithFeatures, getSelectedTrigger],
  (schema, trigger) => schema.getIn([schemaConstants.REMOVED_GLOBAL_CONDITIONS_PER_TRIGGER, trigger])
);

export const getSchemaTriggers = createSelector(
  [getSchemaWithFeatures],
  (schema) => schema.get(constants.TRIGGER_GROUPS));

export const getUserActionFilters = createSelector(
  [getSchemaWithFeatures],
  (schema) => schema.get(constants.USER_ACTION_FILTERS));

export const getUserActionsFilterErrors = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_FILTERS_ERRORS]);
/**
 * returns the conditions for the given trigger as appears in the schema
 * @param state
 * @param trigger
 * @returns {any}
 */
export const getConditionsForTrigger = (state, trigger) => getSchemaWithFeatures(state).getIn([constants.CONDITIONS_PER_TRIGGERS, trigger]);

export const getConditionsForAdvancedRule = createSelector([getConditionsForTrigger], utils.filterConditionsForAdvancedRule);

export const getConditionsPerUserAction = (state) => getSchemaWithFeatures(state).getIn([constants.CONDITIONS_PER_USER_ACTION]);

export const getTempReferralCodeModel = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_REFERRAL_CODE_MODEL]);

export const getDateTimeGlobalConditionSchema = createSelector([getSelectedTrigger, getSchemaWithFeatures], utils.getDateTimeGlobalConditionsImpl);

export const getDateTimeGlobalConditionInitialConditionType = createSelector(getDateTimeGlobalCondition, utils.getDateTimeGlobalConditionInitialConditionTypeImpl);

export const getTempDateTimeGlobalConditionsDatesRangeCondition = createSelector(getTempDateTimeGlobalConditions,
  (tempDateTimeGlobalConditions) => tempDateTimeGlobalConditions ? tempDateTimeGlobalConditions.get(constants.DATES_RANGE_CONDITION) : null);

export const getTempDateTimeGlobalConditionsTimeRangeInDaysOfWeekCondition = createSelector(getTempDateTimeGlobalConditions,
  (tempDateTimeGlobalConditions) => tempDateTimeGlobalConditions ? tempDateTimeGlobalConditions.get(constants.DAYS_TIMES_CONDITION) : null);

export const getTempDateTimeGlobalConditionsType = createSelector(getTempDateTimeGlobalConditions, (tempModel) => tempModel ? tempModel.get(constants.TYPE) : null);

export const getDateTimeGlobalConditionType = createSelector([getTempDateTimeGlobalConditionsType, getDateTimeGlobalConditionInitialConditionType], utils.getDateTimeGlobalConditionTypeImpl);

export const getDisplayName = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.DISPLAY_TAB_NAME]);
export const getDisplayDescription = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.DISPLAY_TAB_DESCRIPTION]);
const getPreviewImageId = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.PREVIEW_IMAGE_ID]);
const getBackgroundImageId = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.BACKGROUND_IMAGE_ID]);
const getStampImageId = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.STAMP_IMAGE_ID]);
export const getSelectedPreviewImage = createSelector([getPreviewImageId, imagesSelectors.imagesListSelector], utils.getSelectedGiftImageImpl);
export const getSelectedBackgroundImage = createSelector([getBackgroundImageId, imagesSelectors.imagesListSelector], utils.getSelectedGiftImageImpl);
export const getSelectedStampImage = createSelector([getStampImageId, imagesSelectors.imagesListSelector], utils.getSelectedGiftImageImpl);
export const getShowRedeemCode = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.SHOW_REDEEM_CODE_IN_APP]);
export const getInteractiveType = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.GIFT_INTERACTIVE_TYPE]);

export const getTempOriginActivity = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_ORIGIN_ACTIVITY]);
export const getNewlyCreatedGiftId = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.NEWLY_CREATED_BENEFIT]);

export const getReferralCodeTempModelCondition = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_REFERRAL_CODE_MODEL]);
export const getReferralCodeTempError = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEMP_FILTERS_ERRORS, constants.TEMP_REFERRAL_CODE_MODEL, constants.CONDITION_VALUE]);

export const getConfirmDeleteActionDialogOpen = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.CONFIRM_DELETE_ACTION_DIALOG_OPEN]);
export const getBlockSmsActionDialogOpen = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.BLOCK_SMS_ACTION_DIALOG_OPEN]);
export const getRequiredPunches = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.REQUIRED_PUNCHES]);
export const getRelatedPunchRule = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_RULE_PUNCH]);
export const getRelatedSendOnExpiraionRule = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_RULE_SEND_ON_EXPIRATION]);
export const getServerId = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.SERVER_ID]);
export const getCommunicationRules = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.PUNCH_CARD_CHILD_ACTIVITIES, constants.PUNCH_CARD_COMMUNICATION_RULES]);
export const getPunchCardAutoRenew = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.PUNCH_CARD_AUTO_RENEW]);
export const getPunchCardRenewTrigger = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.PUNCH_CARD_AUTO_RENEW_TRIGGER]);
export const getChildActivities = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.PUNCH_CARD_CHILD_ACTIVITIES]);
export const getPunchCardRewardMode = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.PUNCH_CARD_REWARD_MODE]);
export const getMentionFieldCopiedValue = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, commonConstants.MENTION_COPIED_VALUE]);
export const getReFilterDialogOpen = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.RE_FILTER_DIALOG_OPEN]);
export const getActivityTags = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.DATA, constants.ACTIVITY_TAGS]);

// Landing Page
export const getLandingPageBaseUrl = (state) => state.getIn([appConstants.APP, appConstants.EXTERNAL_SERVICES_URLS, appConstants.GIFT_LANDING_PAGE_URL]);
export const getLandingPageViewDirection = (state) => state.getIn([appConstants.APP, appConstants.LOCATION_REGION_INFO, appConstants.LANDING_PAGE_VIEW_DIRECTION]);
export const getLandingPageModalData = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.LANDING_PAGE_MODAL_DATA]);

// test to myself
export const getTestToMyselfData = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEST_TO_MYSELF]);
export const getActionValidationErrorsForTest = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEST_TO_MYSELF, constants.VALIDATION_ERRORS]);
export const testToMySelfCompleted = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEST_TO_MYSELF, constants.TEST_TO_MYSELF_COMPLETED]);
export const testToMySelfSucceeded = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEST_TO_MYSELF, constants.TEST_TO_MYSELF_SUCCEEDED]);
export const getTestedActionPath = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEST_TO_MYSELF, constants.ACTION_PATH]);
export const testToMySelfInProgress = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEST_TO_MYSELF, constants.TEST_TO_MYSELF_IN_PROGRESS]);
export const testToMySelfInProgressWrapperIndex = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEST_TO_MYSELF, constants.TEST_TO_MYSELF_IN_PROGRESS_WRAPPER_INDEX]);
export const testToMySelfInProgressIndex = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.TEST_TO_MYSELF, constants.TEST_TO_MYSELF_IN_PROGRESS_INDEX]);

// comillia hint
export const getAdjustByMemberPropertyFlag = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.ADJUST_BY_MEMBER_PROPERTIES]);
export const getComilliaHintOpenInAction = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.COMILLIA_HINT_OPEN_IN_ACTION]);

export const getLookerFetchedFlag = (state) => state.getIn([reducerConstants.ACTIVITY_BRANCH, constants.LOOKER_QUERY_FETCHED]);
