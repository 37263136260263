/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const DEFAULT_LOCALE = 'en';

// Action types
export const DEFAULT_ACTION = 'app/App/DEFAULT_ACTION';
export const SET_HUB_1_PATH = 'app/App/HUB_1_PATH';
export const SET_BROWSER_LOCALE_SETTINGS = 'app/App/SET_BROWSER_LOCALE_SETTINGS';

export const GET_APPLICATION_DATA = 'app/App/GET_APPLICATION_DATA';
export const GET_FRANCHISES_DATA = 'app/App/GET_FRANCHISES_DATA';
export const GET_APPLICATION_DATA_SUCCESS = 'app/App/GET_APPLICATION_DATA_SUCCESS';
export const GET_APPLICATION_DATA_FAIL = 'app/App/GET_APPLICATION_DATA_FAIL';
export const CONTACT_SUPPORT = 'app/App/CONTACT_SUPPORT';
export const CONTACT_SUPPORT_SUCCESS = 'app/App/CONTACT_SUPPORT_SUCCESS';
export const CONTACT_SUPPORT_FAIL = 'app/App/CONTACT_SUPPORT_FAIL';
export const GET_FRANCHISES_DATA_SUCCESS = 'app/App/GET_FRANCHISES_DATA_SUCCESS';
export const GET_MONTHLY_COMMUNICATIONS_LIMITATION_SUCCESS = 'app/App/GET_MONTHLY_COMMUNICATIONS_LIMITATION_SUCCESS';
export const GET_MONTHLY_COMMUNICATIONS_LIMITATION = 'app/App/GET_MONTHLY_COMMUNICATIONS_LIMITATION';
export const GET_APPLICATION_DATA_ERROR = 'app/App/GET_APPLICATION_DATA_ERROR';

export const GET_BUSINESS = 'app/App/GET_BUSINESS';
export const GET_BUSINESS_SUCCESS = 'app/App/GET_BUSINESS_SUCCESS';
export const GET_BUSINESS_ERROR = 'app/App/GET_BUSINESS_ERROR';

export const CHANGE_LOCALE = 'app/App/CHANGE_LOCALE';
export const CHANGE_LOCALE_SUCCESS = 'app/App/CHANGE_LOCALE_SUCCESS';
export const CHANGE_LOCALE_ERROR = 'app/App/CHANGE_LOCALE_ERROR';

export const USER_LOGOUT = 'app/App/USER_LOGOUT';
export const USER_LOGOUT_SUCCESS = 'app/App/USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_ERROR = 'app/App/USER_LOGOUT_ERROR';
export const USER_UPDATE = 'app/App/USER_UPDATE';
export const USER_UPDATE_SUCCESS = 'app/App/User/UPDATE_SUCCESS';
export const USER_UPDATE_ERROR = 'app/App/User/UPDATE_SUCCESS';
export const USER_UPDATE_EMAIL = 'app/App/User/UPDATE_EMAIL_SUCCESS';
export const USER_UPDATE_LANG = 'app/App/User/UPDATE_LANG_SUCCESS';

export const INCREASE_AJAX_REQUESTS_COUNTER = 'app/App/INCREASE_AJAX_REQUESTS_COUNTER';
export const DECREASE_AJAX_REQUESTS_COUNTER = 'app/App/DECREASE_AJAX_REQUESTS_COUNTER';

export const GET_LOCATIONS = 'app/App/GET_LOCATIONS';
export const GET_LOCATIONS_SUCCESS = 'app/App/GET_LOCATIONS_SUCCESS';
export const GET_CUSTOMER = 'app/App/GET_CUSTOMER';
export const SET_DEMO_MODE = 'app/App/SET_DEMO_MODE';
export const SET_DEMO_MODE_SUCCESS = 'app/App/SET_DEMO_MODE_SUCCESS';
export const SET_DEMO_MODE_ERROR = 'app/App/SET_DEMO_MODE_ERROR';

export const GET_VERIFICATION_CODE = 'app/App/GET_VERIFICATION_CODE';
export const GET_VERIFICATION_CODE_SUCCESS = 'app/App/GET_VERIFICATION_SUCCESS';
export const GET_VERIFICATION_CODE_ERROR = 'app/App/GET_VERIFICATION_ERROR';

export const GET_APP_CLIENT_ID = 'app/App/GET_APP_CLIENT_ID';
export const GET_APP_CLIENT_ID_SUCCESS = 'app/App/GET_APP_CLIENT_ID_SUCCESS';
export const GET_APP_CLIENT_ID_ERROR = 'app/App/GET_APP_CLIENT_ID_ERROR';

export const GET_REGISTRATION_FIELDS = 'app/App/GET_REGISTRATION_FIELDS';
export const GET_REGISTRATION_FIELDS_SUCCESS = 'app/App/GET_REGISTRATION_FIELDS_SUCCESS';
export const GET_REGISTRATION_FIELDS_ERROR = 'app/App/GET_REGISTRATION_FIELDS_ERROR';

export const GET_BUSINESSES = 'app/App/GET_BUSINESSES';
export const GET_BUSINESSES_SUCCESS = 'app/App/GET_BUSINESSES_SUCCESS';
export const GET_BUSINESSES_ERROR = 'app/App/GET_BUSINESSES_ERROR';

export const CREATE_MEMBER = 'app/App/CREATE_MEMBER';
export const CREATE_MEMBER_SUCCESS = 'app/App/CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBER_ERROR = 'app/App/CREATE_MEMBER_ERROR';

export const CHANGE_USER_PASSWORD = 'app/App/CHANGE_USER_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'app/App/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'app/App/CHANGE_PASSWORD_ERROR';

export const GO_TO_PAGE = 'app/App/GO_TO_PAGE';
export const GO_TO_WELCOME_PAGE = 'app/App/GO_TO_WELCOME_PAGE';

export const GET_FEATURES = 'app/App/GET_FEATURES';
export const GET_FEATURES_SUCCESS = 'app/App/GET_FEATURES_SUCCESS';
export const CHECK_PAGE_PERMISSIONS = 'app/App/CHECK_PAGE_PERMISSIONS';
export const SET_CUSTOMER_MEMBERSHIP_KEY = 'app/App/SET_CUSTOMER_MEMBERSHIP_KEY';
export const SET_CUSTOMER_DATA_LOADING = 'app/App/SET_CUSTOMER_DATA_LOADING';

export const CANCEL_3RD_PARTY_CODE = 'app/App/CANCEL_3RD_PARTY_CODE';
export const CANCEL_3RD_PARTY_CODE_SUCCESS = 'app/App/CANCEL_3RD_PARTY_CODE_SUCCESS';
export const CANCEL_3RD_PARTY_CODE_FAIL = 'app/App/CANCEL_3RD_PARTY_CODE_FAIL';
export const CANCEL_3RD_PARTY_CLEAR_STATUS = 'app/App/CANCEL_3RD_PARTY_CODE_FAIL';

export const GET_CURRENT_SMS_PROVIDERS = 'app/App/GET_CURRENT_SMS_PROVIDERS';
export const GET_CURRENT_SMS_PROVIDERS_SUCCESS = 'app/App/GET_CURRENT_SMS_PROVIDERS_SUCCESS';
export const GET_CURRENT_SMS_PROVIDERS_ERROR = 'app/App/GET_CURRENT_SMS_PROVIDERS_ERROR';

export const GET_TAGS_SUCCESS = 'app/App/GET_TAGS_SUCCESS';
export const GET_TAGS = 'app/App/GET_TAGS';

export const GET_BUSINESS_TIME_INFO = 'app/App/GET_BUSINESS_TIME_INFO';
export const GET_BUSINESS_TIME_INFO_SUCCESS = 'app/App/GET_BUSINESS_TIME_INFO_SUCCESS';

export const UPDATE_BUSINESS_DATE_FORMAT = 'app/App/UPDATE_BUSINESS_DATE_FORMAT';

export const SET_LOADING_STATE = 'app/App/SET_LOADING_STATE';

// Professional / Essentials
export const REQUEST_TO_UPGRADE_TO_PROFESSIONAL = 'app/App/requestToUpgradeToProfessional';

// Store fields
export const APP = 'app';
export const HUB_1_PATH = 'hub1Path';
export const BUSINESS = 'business';
export const BUSINESS_CSMs = 'businessCsms';
export const BUSINESS_SUPPORT_EMAILS = 'supportEmails';
export const DOMAINS = 'domains';
export const LOCATION_ID = 'locationId';
export const FRANCHISES_FIELD = 'franchiseField';
export const DEFAULT_CONSENT_CONTENT = 'consentContent';
export const ACTION_SMS_CONTENT = 'actionSMSBody';
export const ACTION_EMAIL_CONTENT = 'actionEmailBody';
export const ACTION_EMAIL_SUBJECT = 'actionEmailSubject';
export const UNSUBSCRIBE_MESSAGE = 'unsubscribeMessage';
export const BUSINESS_DISPLAY_NAME = 'displayName';
export const BUNDLE_PLAN = 'bundlePlan';
export const DEFAULT_DISCOUNT_STRATEGY = 'defaultDiscountStrategy';
export const BUSINESSES = 'businesses';
export const CONTACT_CSM_SUPPORT = 'contactSupport';
export const LOCATION_REGION_INFO = 'locationRegionInfo';
export const FRANCHISES = 'franchises';
export const MONTHLY_SMS_MESSAGES_LIMITATION = 'monthlySmsMessagesLimitation';
export const MONTHLY_EMAIL_MESSAGES_LIMITATION = 'monthlyEmailMessagesLimitation';
export const ISO_CURRENCY_SYMBOL = 'isoCurrencySymbol';
export const COUNTRY_CODE = 'countryCode';
export const CURRENCY_SYMBOL = 'currencySymbol';
export const REGISTRATION_FORM_FIELD_NAME = 'name';
export const REGISTRATION_FORM_FIELD_KEY = 'key';
export const REGISTRATION_FORM_FIELD_TITLE = 'title';
export const REGISTRATION_FORM_FIELD_OPTIONS = 'options';
export const LANGUAGES = 'languages';
export const DATE_FORMATS = 'dateFormats';
export const USER = 'user';
export const FRANCHISES_DATA = 'franchisesData';
export const IS_FRANCHISES = 'isFranchise';
export const USER_BUSINESS_LOCATIONS = 'userBusinessLocations';
export const VERIFICATION_CODE = 'verificationCode';
export const REGISTRATION_FIELDS = 'registrationFields';
export const NEW_MEMBER = 'newMember';
export const IS_COMO_USER = 'isComoUser';
export const IS_DEMO_USER = 'isDemo';
export const LOGIN_COUNTER = 'loginCounter';
export const MAX_LOGIN_COUNT_FOR_TUTORIAL = 3;
export const SALES_FORCE_STATUS_ACTIVE = 'Active';
export const SALES_FORCE_STATUS_UNKNOWN = 'Unknown';
export const USER_EMAIL = 'userEmail';
export const USER_TOTP_OTP_PATH = 'userTotpOtpPath';
export const USER_LANG = 'userLang';
export const INITIAL_APP_DATA_LOADED = 'initialAppDataLoaded';
export const TRANSLATIONS = 'translations';
export const SSR_TRANSLATIONS = 'ssrTranslations';
export const LEGAL_SETTINGS = 'legalSettings';
export const LOCALE = 'locale';
export const TIME_ZONE = 'timezone';
export const LANGUAGE_DIRECTION = 'direction';
export const LANGUAGE_KEY = 'value';
export const RTL_LANGUAGE_DIRECTION = 'rtl';
export const BROWSER_LOCALE_SETTINGS = 'browserLocaleSettings';
export const BROWSER_LOCALE = 'browserLocale';
export const DATE_FORMAT = 'dateFormat';
export const TIME_FORMAT = 'timeFormat';
export const FEATURES = 'features';
export const CUSTOMER = 'customer';
export const CUSTOMER_DATA_LOCADING = 'customerDataLoading';
export const MEMBERSHIP_KEY = 'membershipKey';
export const BUSINESS_WALLET = 'businessWallet';
export const APP_SCREENS = 'appScreens';
export const BUILTIN_APP_SCREENS = 'builtin';
export const CUSTOM_APP_SCREENS = 'custom';
export const LIVE_HTTP_REQUESTS_COUNT = 'liveHttpRequestsCount';
export const GET_APPLICATION_DATA_ERROR_CODE = 'getApplicationDataError';
export const CONTACT_SUPPORT_ERROR_CODE = 'contactSupportError';
export const DECIMAL_AMOUNT = 'decimalsAmount';
export const IS_LOADING = 'isLoading';
export const DEAL_SETTINGS = 'dealSettings';
export const LOCATIONS = 'locations';
export const LOCATIONS_GROUPS = 'locationsGroups';
export const EMAIL_FROM_HEADER = 'emailFromHeader';
export const LOOKER_GATEWAY_DOMAIN = 'LookerGatewayDomain';
export const DASHBOARD_URL = 'dashboardUrl';
export const DASHBOARD_TOKEN = 'dashboardToken';
export const GOOGLE_ANALYTICS_CHECK_CODE = 'googleAnalyticsCheckCode';
export const GOOGLE_ANALYTICS_TOKEN_DOMAIN = 'googleAnalyticsTokenDomain';
export const WEBPROPERTY_ID = 'webpropertyId';
export const REPORT_CODE = 'reportCode';
export const EXTERNAL_SERVICES_URLS = 'externalServicesUrls';
export const GIFT_LANDING_PAGE_URL = 'giftLandingPageUrl';
export const CANCEL_3RD_PARTY_CODE_STATUS = 'cancel3rdPartyCodeStatus';
export const CURRENT_SMS_OPERATIONAL_PROVIDER = 'currentSMSOperationalProvider';
export const CURRENT_SMS_MARKETING_PROVIDER = 'currentSMSMarketingProvider';
export const USER_PHONE_NUMBER = 'phone';
export const USER_EMAIL_ADDRESS = 'email';
export const BUSINESS_TAGS = 'businessTags';
export const TAGS = 'tags';
export const LAST_TAGS_RETRIEVAL_TIME = 'retrieved';
export const TIME_TO_RETRIEVE_MILLISECONDS = 10 * 60 * 1000;
export const COMO_ACADEMY_URL = 'comoAcademyUrl';
export const BUSINESS_DATE_TIME = 'businessDateTime';

// Features
export const FEATURES_APP = 'App';

export const FEATURES_BEACON_SERVICE = 'Beacon Services';
export const FEATURES_DISCOUNT = 'Discount';
export const FEATURES_SMS = 'Send Member Sms';
export const FEATURES_WALLET_PASS = 'Wallet Pass';
export const FEATURES_MARKETING_EMAILS = 'MarketingEmails';
export const FEATURES_PAY_WITH_BUDGET = 'Pay With Budget';
export const FEATURES_SHOPPING_CART_GET_MEMBER_BENEFITS_AND_REDEEM = 'Shopping Cart - Get Member Benefits and Redeem';
export const FEATURES_SHOPPING_CART_SUBMIT_PURCHASE = 'Shopping Cart - Submit Purchase';
export const FEATURES_LOCATIONS_GROUPS = 'Locations Groups';
export const FEATURES_PAYPAL_SERVICES = 'Paypal Services';
export const FEATURES_ZOOZ_SERVICES = 'Zooz Services';
export const FEATURES_MISHLOHA_SERVICES = 'Mishloha Services';
export const FEATURES_PRODUCT_TEMPLATES = 'Product Templates';
export const FEATURES_CAMPAIGN_CENTER = 'Campaign Center';
export const FEATURES_NO_POS_GIFT = 'No Pos Gift';
export const FEATURES_SENSITIVE_SETTINGS = 'Sensitive Settings';
export const FEATURES_ADVANCED_PUSH = 'AdvancedPush';
export const FEATURES_MMS = 'Send Member MMS';
export const FEATURE_POS_VERSION_4_0 = 'API_4_0';
export const FEATURE_POS_VERSION_2_8 = 'API_2_8';
export const FEATURE_NON_MEMBER_DEAL = 'Non Member Deal';
export const FEATURE_ACTIVITY_TAGS = 'Activity Tags';
export const FEATURE_FILTER_ACTIVITY_INPUT = 'Filter Activity Input';
export const DISABLE_RE_FILTERING_FLAG = 'Disable ReFilter Communication';
export const FEATURE_ARTIFICIAL_INTELLIGENCE = 'Artificial Intelligence';
export const FEATURE_EXTERNAL_APP = 'External App';

export const FEATURES_CATEGORY_POS = 'pos';
export const FEATURES_CATEGORY_NO_POS_INTEGRATION = 'noPosIntegration';
export const FEATURES_CATEGORY_ADD_ON = 'addOnAskSupport';


export const LANGUAGE_CODES = {
  1: 'en',
  2: 'he',
  4: 'tr',
  7: 'es',
  8: 'fr',
  9: 'ru',
  16: 'it',
  19: 'numeric'
};

export const CUSTOMER_IDENTIFIER_PHONE = 'PhoneNumber';
export const CUSTOMER_IDENTIFIER_EMAIL = 'Email';
export const CUSTOMER_IDENTIFIER_KEY = 'MembershipKey';

export const FEATURE_NAME = 'name';
export const FEATURE_TYPE = 'type';
export const FEATURE_LEVEL = 'level';
export const FEATURE_LEVEL_FULL = 'Full';
export const FEATURE_LEVEL_READ = 'Read';
export const FEATURE_LEVEL_NONE = 'None';
export const FEATURE_NAME_VIEW_DATA = 'View Data';
export const FEATURE_NAME_DATA_REPORTS = 'Data Reports';
export const REVEL = 'revel';
export const TRIAL = 'trial';
export const DATA_ONLY = 'dataOnly';
export const FEATURE_NAME_POS_AND_CONNECTIVITY = 'Pos Connectivity';
export const FEATURE_NAME_CUSTOMER_PORTAL = 'CustomerPortal';
export const FEATURE_NAME_CAMPAIGN_CENTER = 'Campaign Center';
export const FEATURE_NAME_ESSENTIALS = 'Essentials';
export const FEATURE_NAME_POINTS_SHOP = 'PointsShop';
export const FEATURE_NAME_PROMO_CODES = 'PromoCodes';
export const FEATURE_NAME_AI_CAMPAIGN = 'AiCampaign';


export const FEATURE_NAME_ESSENTIALS_OFF = 'EssentialsOff';
export const FEATURE_NAME_BASIC_PLAN_EXCLUDE = 'BasicPlanExclude';
export const FEATURE_PLAN = 'plan';
export const FEATURE_ESSENTIALS_OFF = {
  name: FEATURE_NAME_ESSENTIALS_OFF,
  category: FEATURES_CATEGORY_ADD_ON,
  type: FEATURE_PLAN
};
export const FEATURE_BASIC_PLAN_EXCLUDE = {
  name: FEATURE_NAME_BASIC_PLAN_EXCLUDE,
  category: FEATURES_CATEGORY_ADD_ON,
  type: FEATURE_PLAN
};
export const FEATURE_NAME_BUSINESS_SETTINGS = 'Business Settings';
export const FEATURE_NAME_FIND_CUSTOMER = 'Find';
export const FEATURE_NAME_FILTER = 'FilterAndActions';
export const FEATURE_NAME_DATA_EXPORT = 'Data Export';
export const FEATURE_ACTIONS_ON_BULK_OF_MEMBER = 'Actions on Bulk of Members';
export const FEATURE_NAME_APP = 'View App';
export const FEATURE_NAME_CREATE_NEW_MEMBER = 'Create New Member';
export const FEATURE_CANCEL_3RD_PARTY_CODE = 'Cancel 3rd party code';
export const FEATURE_SEE_VERIFICATION_CODE = 'See verification code';
export const FEATURE_GIFT_LANDING_PAGE = 'Gift Landing Page';
export const FEATURE_HELP_MENU_TAB = 'Help Menu';


export const LANDING_PAGE_VIEW_DIRECTION = 'landingPageViewDirection';
export const LEGAL_TEXT_WITH_BUSINESS_TERMS = 'registration.legal.section.with.location.terms';
export const LEGAL_TEXT = 'registration.legal.section';
